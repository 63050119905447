import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/uguryildirim/git/eteration/ui-components/node_modules/gatsby-theme-docz/src/base/Layout.js";
import Playground from 'components/Playground';
import Nav from "components/Nav";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "nav"
    }}>{`Nav`}</h1>

    <Playground __position={0} __code={'<Nav>\n  <Nav.Item>\n    <Nav.Link href=\"#\">Link</Nav.Link>\n  </Nav.Item>\n  <Nav.Item>\n    <Nav.Link href=\"#\">Link</Nav.Link>\n  </Nav.Item>\n  <Nav.Item>\n    <Nav.Link href=\"#\">Another Link</Nav.Link>\n  </Nav.Item>\n  <Nav.Item>\n    <Nav.Link disabled href=\"#\">\n      Disabled Link\n    </Nav.Link>\n  </Nav.Item>\n</Nav>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Nav,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Nav mdxType="Nav">
    <Nav.Item>
      <Nav.Link href="#">Link</Nav.Link>
    </Nav.Item>
    <Nav.Item>
      <Nav.Link href="#">Link</Nav.Link>
    </Nav.Item>
    <Nav.Item>
      <Nav.Link href="#">Another Link</Nav.Link>
    </Nav.Item>
    <Nav.Item>
      <Nav.Link disabled href="#">Disabled Link</Nav.Link>
    </Nav.Item>
  </Nav>
    </Playground>
    <Playground __position={1} __code={'<Nav vertical>\n  <Nav.Item>\n    <Nav.Link href=\"#\">Link</Nav.Link>\n  </Nav.Item>\n  <Nav.Item>\n    <Nav.Link href=\"#\">Link</Nav.Link>\n  </Nav.Item>\n  <Nav.Item>\n    <Nav.Link href=\"#\">Another Link</Nav.Link>\n  </Nav.Item>\n  <Nav.Item>\n    <Nav.Link disabled href=\"#\">\n      Disabled Link\n    </Nav.Link>\n  </Nav.Item>\n</Nav>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Nav,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Nav vertical mdxType="Nav">
    <Nav.Item>
      <Nav.Link href="#">Link</Nav.Link>
    </Nav.Item>
    <Nav.Item>
      <Nav.Link href="#">Link</Nav.Link>
    </Nav.Item>
    <Nav.Item>
      <Nav.Link href="#">Another Link</Nav.Link>
    </Nav.Item>
    <Nav.Item>
      <Nav.Link disabled href="#">Disabled Link</Nav.Link>
    </Nav.Item>
  </Nav>
    </Playground>
    <Playground __position={2} __code={'<Nav tabs>\n  <Nav.Item>\n    <Nav.Link href=\"#\" active>\n      Link\n    </Nav.Link>\n  </Nav.Item>\n  <Nav.Item>\n    <Nav.Link href=\"#\">Link</Nav.Link>\n  </Nav.Item>\n  <Nav.Item>\n    <Nav.Link href=\"#\">Another Link</Nav.Link>\n  </Nav.Item>\n  <Nav.Item>\n    <Nav.Link disabled href=\"#\">\n      Disabled Link\n    </Nav.Link>\n  </Nav.Item>\n</Nav>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Nav,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Nav tabs mdxType="Nav">
    <Nav.Item>
      <Nav.Link href="#" active>Link</Nav.Link>
    </Nav.Item>
    <Nav.Item>
      <Nav.Link href="#">Link</Nav.Link>
    </Nav.Item>
    <Nav.Item>
      <Nav.Link href="#">Another Link</Nav.Link>
    </Nav.Item>
    <Nav.Item>
      <Nav.Link disabled href="#">Disabled Link</Nav.Link>
    </Nav.Item>
  </Nav>
    </Playground>
    <Playground __position={3} __code={'<Nav pills>\n  <Nav.Item>\n    <Nav.Link href=\"#\" active>\n      Link\n    </Nav.Link>\n  </Nav.Item>\n  <Nav.Item>\n    <Nav.Link href=\"#\">Link</Nav.Link>\n  </Nav.Item>\n  <Nav.Item>\n    <Nav.Link href=\"#\">Another Link</Nav.Link>\n  </Nav.Item>\n  <Nav.Item>\n    <Nav.Link disabled href=\"#\">\n      Disabled Link\n    </Nav.Link>\n  </Nav.Item>\n</Nav>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Nav,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Nav pills mdxType="Nav">
    <Nav.Item>
      <Nav.Link href="#" active>Link</Nav.Link>
    </Nav.Item>
    <Nav.Item>
      <Nav.Link href="#">Link</Nav.Link>
    </Nav.Item>
    <Nav.Item>
      <Nav.Link href="#">Another Link</Nav.Link>
    </Nav.Item>
    <Nav.Item>
      <Nav.Link disabled href="#">Disabled Link</Nav.Link>
    </Nav.Item>
  </Nav>
    </Playground>
    <h3 {...{
      "id": "props"
    }}>{`Props`}</h3>
    <p><a href="https://reactstrap.github.io/components/navs/" target="_blank">Reactstrap Nav</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      